import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { Card } from '@abyss/web/ui/Card';
import { DataTable } from '@abyss/web/ui/DataTable';
import React from 'react';

const jobData = [
  {
    submissionID: '1324-13131-1313-1313',
    receivedDateTime: '',
    releaseDateTime: '8.20.24 10:32',
    timeDuration: '',
    action: 'Completed ',
    status: '',
    outcome: 'Member selected -Jane Doe, Policy 1323423, ID 999323232',
    analyst: 'Nancy Drew',
    subRows: [
      {
        submissionID: '1324-13131-1313-1313',
        receivedDateTime: 'a',
        releaseDateTime: '',
        timeDuration: '2 minutes',
        action: 'Routed to Fallout Queue ',
        status: 'Not Started',
        outcome: '',
        analyst: 'DEAR platform auto route',
      },
      {
        submissionID: '1324-13131-1313-1313',
        receivedDateTime: 'b',
        releaseDateTime: 'c',
        timeDuration: '3 min',
        action: '',
        status: 'In Progress',
        outcome: '',
        analyst: 'Jane Brown',
      },
      {
        submissionID: '1324-13131-1313-1313',
        receivedDateTime: 'c',
        releaseDateTime: 'd',
        timeDuration: '20 mins',
        action: '',
        status: 'Hold',
        outcome: '',
        analyst: 'Jane Brown',
      },
      {
        submissionID: '1324-13131-1313-1313',
        receivedDateTime: 'd',
        releaseDateTime: 'e',
        timeDuration: '2 mins',
        action: 'Reassigned',
        status: '',
        outcome: '',
        analyst: 'Tina Monroe',
      },
      {
        submissionID: '1324-13131-1313-1313',
        receivedDateTime: 'e',
        releaseDateTime: 'f',
        timeDuration: '2 mins',
        action: '',
        status: 'In Progress',
        outcome: '',
        analyst: 'Nancy Drew',
      },
      {
        submissionID: '1324-13131-1313-1313',
        receivedDateTime: 'f',
        releaseDateTime: 'g',
        timeDuration: '2 mins',
        action: 'Completed ',
        status: '',
        outcome: 'Member selected -Jane Doe, Policy 1323423, ID 999323232',
        analyst: 'Nancy Drew',
      },
      {
        submissionID: 'TotalDuration',
        receivedDateTime: '',
        releaseDateTime: '',
        timeDuration: '27 mins',
        action: '',
        status: '',
        outcome: '',
        analyst: '',
      },
    ],
  },
  // {
  //   submissionID: '1324-13131-1313-1313',
  //   receivedDateTime: '',
  //   releaseDateTime: '8.20.24 10:32',
  //   timeDuration: '',
  //   action: 'Rejected',
  //   status: '',
  //   outcome: 'Member selected -Jane Doe, Policy 1323423, ID 999323232',
  //   analyst: 'Nancy Drew',
  //   subRows: [
  //     {
  //       submissionID: '1324-13131-1313-1313',
  //       receivedDateTime: '8.20.24 10:05a',
  //       releaseDateTime: '8.20.24 10:07',
  //       timeDuration: '2 minutes',
  //       action: 'Routed to Fallout Queue ',
  //       status: 'Not Started',
  //       outcome: '',
  //       analyst: 'DEAR platform auto route',
  //     },
  //     {
  //       submissionID: '1324-13131-1313-1313',
  //       receivedDateTime: '8.20.24 10:07a',
  //       releaseDateTime: '8.20.24 10:30a',
  //       timeDuration: '3 min',
  //       action: '',
  //       status: 'In Progress',
  //       outcome: '',
  //       analyst: 'Jane Brown',
  //     },
  //     {
  //       submissionID: '1324-13131-1313-1313',
  //       receivedDateTime: '8.20.24 10:30a',
  //       releaseDateTime: '8.20.24 10:30a',
  //       timeDuration: '20 mins',
  //       action: '',
  //       status: 'Hold',
  //       outcome: '',
  //       analyst: 'Jane Brown',
  //     },
  //     {
  //       submissionID: '1324-13131-1313-1313',
  //       receivedDateTime: '',
  //       releaseDateTime: '8.20.24 10:30',
  //       timeDuration: '2 mins',
  //       action: 'Reassigned',
  //       status: '',
  //       outcome: '',
  //       analyst: 'Tina Monroe',
  //     },
  //     {
  //       submissionID: '1324-13131-1313-1313',
  //       receivedDateTime: '8.20.24 10:30am',
  //       releaseDateTime: '8.20.24 10:32',
  //       timeDuration: '2 mins',
  //       action: '',
  //       status: 'In Progress',
  //       outcome: '',
  //       analyst: 'Nancy Drew',
  //     },
  //     {
  //       submissionID: '1324-13131-1313-1313',
  //       receivedDateTime: '',
  //       releaseDateTime: '8.20.24 10:32',
  //       timeDuration: '2 mins',
  //       action: 'Rejected  ',
  //       status: '',
  //       outcome: 'Member selected -Jane Doe, Policy 1323423, ID 999323232',
  //       analyst: 'Nancy Drew',
  //     },
  //     {
  //       submissionID: 'TotalDuration',
  //       receivedDateTime: '',
  //       releaseDateTime: '',
  //       timeDuration: '27 mins',
  //       action: '',
  //       status: '',
  //       outcome: '',
  //       analyst: '',
  //     },
  //   ],
  // },
];

export const AuditTrail = () => {
  const recentTableColumns = React.useMemo(() => {
    return [
      {
        Header: 'Job ID',
        accessor: 'submissionID',
        Cell: ({ row, value }) => {
          const isSubRow = row.depth > 0;
          return (
            <div style={{ color: isSubRow ? '#003C71' : '' }}>{value}</div>
          );
        },
      },
      {
        Header: 'File Name',
        accessor: 'receivedDateTime',
        Cell: ({ row, value }) => {
          const isSubRow = row.depth > 0;
          return (
            <div style={{ color: isSubRow ? '#003C71' : '' }}>{value}</div>
          );
        },
      },
      {
        Header: 'Received Date and Time stamp',
        accessor: 'recievedDateTime',
        Cell: ({ row, value }) => {
          const isSubRow = row.depth > 0;
          return (
            <div style={{ color: isSubRow ? '#003C71' : '' }}>{value}</div>
          );
        },
      },
      {
        Header: 'Release Date & Time Stamp',
        accessor: 'releaseDateTime',
        Cell: ({ row, value }) => {
          const isSubRow = row.depth > 0;
          let displayValue = value;

          if (row.depth > 0) {
            // Check if it's a sub-row
            const parentIndex = parseInt(row.id.split('.')[0], 10);
            const subIndex = parseInt(row.id.split('.')[1], 10);
            const parentRow = row.originalSubRows[parentIndex];
            if (parentRow && subIndex < parentRow.subRows.length - 1) {
              displayValue =
                parentRow.subRows[subIndex + 1].original.receivedDateTime;
            }
          } else if (row.index < row.subRows.length - 1) {
            // Check if it's a parent row
            const nextRow = row.subRows[row.index + 1];
            if (nextRow && nextRow.original) {
              displayValue = nextRow.original.receivedDateTime;
            }
          }
          return (
            <div style={{ color: isSubRow ? '#003C71' : '' }}>
              {displayValue}
            </div>
          );
        },
      },
      {
        canGroupBy: true,
        Header: 'Time Duration',
        accessor: 'timeDuration',
        Cell: ({ row, value }) => {
          const isSubRow = row.depth > 0;
          return (
            <div style={{ color: isSubRow ? '#003C71' : '' }}>
              {`${row.original.receivedDateTime}-${row.original.releaseDateTime}`}
            </div>
          );
        },
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row, value }) => {
          const isSubRow = row.depth > 0;
          return (
            <div style={{ color: isSubRow ? '#003C71' : '' }}>{value}</div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row, value }) => {
          const isSubRow = row.depth > 0;
          return (
            <div style={{ color: isSubRow ? '#003C71' : '' }}>{value}</div>
          );
        },
      },
      {
        Header: 'Outcome',
        accessor: 'outcome',
        Cell: ({ row, value }) => {
          const isSubRow = row.depth > 0;
          return (
            <div style={{ color: isSubRow ? '#003C71' : '' }}>{value}</div>
          );
        },
      },
      {
        Header: 'Analyst/User',
        accessor: 'analyst',
        Cell: ({ row, value }) => {
          const isSubRow = row.depth > 0;
          return (
            <div style={{ color: isSubRow ? '#003C71' : '' }}>{value}</div>
          );
        },
      },
    ];
  }, []);

  const recentTableData = useDataTable({
    initialData: jobData,
    initialColumns: recentTableColumns,
    noDataMessage: 'No results',
    showDownloadButton: true,
    showExpansion: true,
    // showGlobalFilter: true,
    // showFilterDataset: true,
    // showTableSettings: true,
    highlightRowOnHover: true,
    // defaultTableSettings: {
    //   hideEmptyColumns: true,
    //   rowHeight: 'cozy',
    // },
    filterColumnTypes: {
      releaseDateTime: {
        type: 'date',
        // options: [
        //   { value: 'Option 1', label: 'Option 1' },
        //   { value: 'Option 2', label: 'Option 2' },
        // ],
      },
      receivedDateTime: {
        type: 'date',
        // options: [
        //   { value: 'Option 1', label: 'Option 1' },
        //   { value: 'Option 2', label: 'Option 2' },
        // ],
      },
    },
    // enableGroupBy: true,
    // initialState: { groupBy: ['timeDuration'] },
  });

  return (
    <React.Fragment>
      <React.Fragment>
        {/* <Router.MetaTags title="Audit Trail" /> */}
        <React.Fragment>
          <Card collapse header="Closed Submissions">
            <Card.Section
              css={{
                'abyss-card-section': {
                  paddingLeft: '10px',
                  paddingRight: '10px',
                },
              }}
            >
              {/* <Grid align="center" style={{ marginBottom: '10px' }}>
                <Grid.Col>
                  <TextInput
                    label="Submission ID"
                    isClearable
                    type="search"
                    validators={{ required: 'Error: Enter a submission ID' }}
                  />
                </Grid.Col>
                <Grid>
                  <Grid.Col style={{ paddingTop: '38px' }}>
                    <Button
                      type="reset"
                      style={{
                        width: '120px',
                      }}
                    >
                      Reset
                    </Button>
                  </Grid.Col>
                  <Grid.Col style={{ paddingTop: '38px' }}>
                    <Button
                      style={{
                        width: '120px',
                      }}
                    >
                      Search
                    </Button>
                  </Grid.Col>
                </Grid>
              </Grid> */}

              <DataTable
                title="Recent Submissions"
                tableState={recentTableData}
                css={{
                  'abyss-data-table-subheader-lower-container': { order: 1 },
                  'abyss-data-table-subheader-top-container': {
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingBottom: '20px',
                  },
                  'abyss-data-table-pagination-bottom-root': {
                    display: 'none',
                  },
                  'abyss-data-table-pagination-top-root': {
                    display: 'contents',
                  },
                  'abyss-data-table-header': { display: 'none' },
                }}
              />
            </Card.Section>
          </Card>
        </React.Fragment>
      </React.Fragment>
    </React.Fragment>
  );
};
