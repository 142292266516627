import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { DataTable } from '@abyss/web/ui/DataTable';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import { useJobIDQuery } from '@src/api/queries';
import { getFlnStorage, setFlnStorage } from '@src/Utilities';
import React, { useCallback, useEffect, useState } from 'react';

const MemberInfo = ({ sendDataToParent }) => {
  const [showRowIndex, setShowRowIndex] = useState<number | null>(null);
  const { getRouteParams } = useRouter();
  const { jobId } = getRouteParams();
  const [clicked, setClicked] = useState<boolean>(false);
  const { data: jobIDResult, isLoading } = useJobIDQuery(jobId);
  const [recordId, setRecordId] = useState(null);
  const columns = React.useMemo(() => {
    return [
      {
        Header: (
          <div className="header-memberID" style={{ color: '#003C71' }}>
            Member ID
          </div>
        ),
        accessor: 'memberId',
      },
      {
        Header: (
          <div className="header-memberLastName" style={{ color: '#003C71' }}>
            First Name
          </div>
        ),
        accessor: 'memberFirstName',
      },
      {
        Header: (
          <div className="header-memberLastName" style={{ color: '#003C71' }}>
            Last Name
          </div>
        ),
        accessor: 'memberLastName',
      },
      {
        Header: 'Date of Birth',
        accessor: 'dob',
      },
      {
        Header: <div style={{ color: '#003C71' }}>Policy Number</div>,
        accessor: 'policyNumber',
      },
      {
        Header: 'Source Code',
        accessor: 'sourceCode',
      },
      {
        Header: 'Government Code',
        accessor: 'governmentCode',
      },
      {
        Header: 'Member State',
        accessor: 'memberState',
      },
      {
        Header: 'Start Date of Service',
        accessor: 'startDateOfService',
      },
      {
        Header: 'End Date of Service',
        accessor: 'endDateOfService',
      },
      {
        Header: 'Policy Effective Date',
        accessor: 'policyEffectiveDate',
      },
      {
        Header: 'Policy Termination Date',
        accessor: 'policyTerminationDate',
        width: 180,
      },
    ];
  }, []);

  const handleRowSelection = useCallback(
    (row) => {
      if (clicked && showRowIndex !== row.index) {
        return false;
      }
      if (row?.isSelected) {
        const recordId = row?.original?.recordId;
        setFlnStorage(jobId, 'selectedIndex', row.index);
        setShowRowIndex(row.index);
        if (recordId) {
          setRecordId(recordId);
        }
      }
      return true;
    },
    [showRowIndex, jobId, clicked, sendDataToParent]
  );

  const dataTableProps = useDataTable({
    initialColumns: columns,
    singleSelection: (row: any) => handleRowSelection(row),
    showPagination: true,
  });

  useEffect(() => {
    if (jobIDResult) {
      dataTableProps.setData(jobIDResult?.data);
      sendDataToParent({
        falloutStatus: jobIDResult?.data[0]?.falloutJob?.falloutStatus,
        recordId: recordId,
      });
    }
  }, [jobIDResult, dataTableProps, sendDataToParent]);

  useEffect(() => {
    if (showRowIndex !== null && jobId) {
      setFlnStorage(jobId, 'fln', jobId);
    }
  }, [jobId, showRowIndex]);

  useEffect(() => {
    const savedflndata = getFlnStorage(jobId);
    const savedIndex = savedflndata?.selectedIndex;
    const holdState = savedflndata?.holdState;

    if ((savedIndex !== null && savedIndex !== undefined) || holdState) {
      const rowIndex = parseInt(savedIndex, 10);
      dataTableProps?.rows?.[rowIndex]?.toggleRowSelected(true);

      setShowRowIndex(rowIndex);
      if (holdState === 'Hold') {
        setClicked(true);
      } else {
        setClicked(false);
      }
    }
  }, [dataTableProps, jobId, clicked]);

  return (
    <React.Fragment>
      <LoadingOverlay
        loadingTitle="Please Wait"
        loadingMessage="Retrieving Information."
        statusTitle="Status update"
        statusMessage="Sample message..."
        isDismissable
        ariaLoadingLabel="Example loading aria label"
        isLoading={isLoading}
      >
        <DataTable
          tableState={dataTableProps}
          title=""
          css={{
            'abyss-data-table-header': { display: 'none', paddingTop: '20px' },
            'abyss-data-table-subheader-lower-container': { order: 1 },
            'abyss-data-table-pagination-bottom-root': {
              display: 'none',
            },
            'abyss-data-table-pagination-top-root': {
              display: 'contents',
            },
          }}
        />
      </LoadingOverlay>
    </React.Fragment>
  );
};

export { MemberInfo };
