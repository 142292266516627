import { useEffect, useMemo } from 'react';
import { useStopwatch } from 'react-timer-hook';

export const Timer = ({ falloutStatus, createdTimestamp }) => {
  const initialTimes = useMemo(() => {
    return new Date(createdTimestamp).getTime() / 1000;
  }, [createdTimestamp]);

  const stopwatchOffset = useMemo(() => {
    const offset = new Date();
    const startTime = initialTimes;
    const currentTime = offset.getTime() / 1000;
    let remainingTime = currentTime - startTime;

    // Handle negative scenarios
    if (remainingTime < 0) {
      remainingTime = 0;
    }
    offset.setSeconds(offset.getSeconds() + remainingTime);
    return offset;
  }, [initialTimes]);

  const { seconds, minutes, hours, start, pause, days } = useStopwatch({
    autoStart: true,
    offsetTimestamp: stopwatchOffset,
  });

  useEffect(() => {
    if (
      falloutStatus === '' ||
      falloutStatus === 'undefined' ||
      falloutStatus === 'null'
    ) {
      pause();
    } else {
      start();
    }
  }, [falloutStatus]);
  const totalMinutes = (days * 24 + hours) * 60 + minutes;
  const formattedSeconds = String(seconds).padStart(2, '0');

  return (
    // <div style={{ textAlign: 'center' }}>
    <div style={{ fontSize: '16px' }}>
      <span>{totalMinutes}</span>:<span>{formattedSeconds}</span>
      <br />
      {/* </div> */}
    </div>
  );
};
