// import localStorage from 'react-secure-storage';

export const getFlnStorage = (jobId: any) => {
  const storedFlnData = localStorage.getItem(`${jobId}`);
  return storedFlnData ? JSON.parse(storedFlnData as any) : {};
};

export const setFlnStorage = (jobId, key, value) => {
  const existingflnData = getFlnStorage(jobId);
  const newData = { ...existingflnData, [key]: value };
  // flnData[key] = value;
  localStorage.setItem(`${jobId}`, JSON.stringify(newData));
};
export function removeFromLocalStorage(jobId) {
  const storageKey = `${jobId}`;
  if (localStorage.getItem(storageKey)) {
    localStorage.removeItem(storageKey);
  } else {
    console.log(`Item with key ${storageKey} does not exist in localStorage`);
  }
}
