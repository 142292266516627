import { useQuery } from '@tanstack/react-query';
import { getByJobID, getJobIDs } from './apis';
type DataTableData = {
  jobId: string;
  filename: string;
  createdTimestamp: string;
  targetDateTimestamp: string;
  assignedTo: string;
  falloutStatus: string;
};
const useJobIDQuery = (jobID) => {
  return useQuery({
    // enabled: false,
    queryKey: ['jobID', jobID],
    queryFn: () => {
      return getByJobID(jobID);
    },
  });
};
const useFalloutQuery = () => {
  return useQuery<DataTableData[]>({
    queryKey: ['falloutData'],
    queryFn: getJobIDs,
    //  invalidateQueries('falloutQuery'),
    // staleTime: 5000,
    refetchIntervalInBackground: true,
    refetchInterval: 300000,
  });
};

export { useJobIDQuery, useFalloutQuery };
