import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useForm } from '@abyss/web/hooks/useForm';
import { useOverlay } from '@abyss/web/hooks/useOverlay';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Box } from '@abyss/web/ui/Box';
import { Breadcrumbs } from '@abyss/web/ui/Breadcrumbs';
import { Button } from '@abyss/web/ui/Button';
import { Card } from '@abyss/web/ui/Card';
import { DataTable } from '@abyss/web/ui/DataTable';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { Router } from '@abyss/web/ui/Router';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { TextInput } from '@abyss/web/ui/TextInput';
import { useActionMutation } from '@src/api/mutation';
import { removeFromLocalStorage } from '@src/Utilities';
import React, { useState } from 'react';
import { MemberInfo } from './MemberInfo';

export const Detail = () => {
  const [currentStatus, setCurrentStatus] = useState<string>('');
  const [recordId, setRecordId] = useState<string>('');

  const handleDataFromChild = (data: {
    falloutStatus: string;
    recordId: string;
  }) => {
    setCurrentStatus(data?.falloutStatus);
    setRecordId(data?.recordId);
  };
  const { mutateAsync: actionMutation, error: uploadError } =
    useActionMutation();
  const { getRouteParams, navigate } = useRouter();
  const { jobId } = getRouteParams();

  const modal = useOverlay('Reject Reason');

  const [released, setReleased] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onHold = 'ON_HOLD';
  const rejected = 'REJECTED';
  const unHold = 'UN_HOLD';
  const completed = 'COMPLETED';
  const inProgress = 'INPROGRESS';
  const onSubmit = async () => {
    await actionMutation({
      falloutStatus: completed,
      jobId: jobId,
      recordId: recordId,
      userAction: completed,
    });
    removeFromLocalStorage(jobId);
    navigate('/internal/home');
  };
  const onRejectClick = () => {
    modal.open();
  };

  const onHoldClick = async () => {
    setReleased(true);
    await actionMutation({
      falloutStatus:
        currentStatus === 'SLA_RISK' || currentStatus === 'DELAYED'
          ? currentStatus
          : onHold,
      jobId: jobId,
      userAction: onHold,
    });
  };
  const onRelease = async () => {
    setReleased(false);
    await actionMutation({
      falloutStatus:
        currentStatus === 'SLA_RISK' || currentStatus === 'DELAYED'
          ? currentStatus
          : inProgress,
      jobId: jobId,
      userAction: unHold,
    });
  };

  const rejectForm = useForm();
  const rejectCodes = rejectForm.watch('rejectCode');

  const rejectReason = rejectForm.watch('rejectReason');
  const rejectDescription = rejectForm.watch('rejectDescription');

  const onRejectSubmit = async (data) => {
    if (!data.rejectReason) {
      rejectForm.setError('rejectReason', {
        type: 'required',
        message: 'Enter Reject Reason',
      });
    }
    if (!data.rejectCode) {
      rejectForm.setError('rejectCode', {
        type: 'required',
        message: 'Enter Reject Code',
      });
    }
    if (!data.rejectDescription) {
      rejectForm.setError('rejectDescription', {
        type: 'required',
        message: 'Enter Reject Description',
      });
    }

    if (
      data.rejectCode !== '' &&
      data.rejectReason !== '' &&
      data.rejectDescription !== ''
    ) {
      await actionMutation({
        falloutStatus: rejected,
        jobId: jobId,
        rejectedReasonType: rejectReason,
        rejectedReasonCode: rejectCodes,
        rejectedReasonDescription: rejectDescription,
        userAction: rejected,
      });
      navigate('/internal/home');
    }
  };
  const onRejectCancel = (data) => {
    rejectForm.clearErrors();
    rejectForm.reset();
    modal.close();
  };

  const data = [
    {
      rejectCode: '',
      rejectReason: '',
      rejectDescription: '',
    },
  ];
  const rejectReasonsMap = {
    'R001 - MNF': [
      {
        value: 'Date of service is not within the Effective Date Range',
        label: 'Date of service is not within the Effective Date Range',
      },
    ],
    'R002 - MOS': [
      {
        value: 'Member Found, but Source System NOT EQUAL to CDB',
        label: 'Member Found, but Source System NOT EQUAL to CDB',
      },
      {
        value: 'Enrollee Source Code is NOT IN (CS, AP, CR, CO)',
        label: 'Enrollee Source Code is NOT IN (CS, AP, CR, CO)',
      },
      {
        value: 'Member Found, but Coverage Type NOT EQUAL to M',
        label: 'Member Found, but Coverage Type NOT EQUAL to M',
      },
    ],
  };
  const rejectColumns = React.useMemo(
    () => [
      {
        Header: 'Reject Code',
        accessor: 'rejectCode',
        minWidth: 100,
        Cell: ({ value, cellActions, row }) => {
          return (
            <SelectInput
              placeholder="Select Reject Code"
              hideLabel
              options={Object.keys(rejectReasonsMap).map((code) => ({
                value: code,
                label: code,
              }))}
              model="rejectCode"
              css={{
                'abyss-select-input-root': {
                  padding: '10px',
                },
              }}
              onChange={(val) => {
                cellActions.modifyRow(row, {
                  rejectCode: val,
                });
              }}
              label={''}
            />
          );
        },
      },
      {
        Header: 'Reject Reason',
        accessor: 'rejectReason',
        minWidth: 250,
        Cell: ({ value, cellActions, row }) => {
          const rejectCode = row?.original?.rejectCode;
          const reasonOptions = rejectReasonsMap[rejectCode] || [];
          return (
            <SelectInput
              placeholder="Select Reject Reason"
              hideLabel
              options={reasonOptions}
              model="rejectReason"
              css={{
                'abyss-select-input-root': {
                  padding: '10px',
                },
              }}
              onChange={(val) => {
                cellActions.modifyRow(row, { rejectReason: val });
              }}
              label={''}
            />
          );
        },
      },
      {
        Header: 'Reject Description',
        accessor: 'rejectDescription',
        minWidth: 250,
        Cell: ({ cellActions, row }) => {
          return (
            <TextInput
              placeholder="Enter Reject Description"
              model="rejectDescription"
              onChange={(val) => {
                cellActions.modifyRow(row, { rejectDescription: val });
              }}
              label={''}
              css={{
                'abyss-text-input-root': {
                  paddingBottom: '4px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                },
              }}
            />
          );
        },
      },
    ],
    []
  );

  const rejectDataTableProps = useDataTable({
    initialColumns: rejectColumns,
    initialData: data,
    showPagination: false,
    noDataMessage: 'No results',
  });

  return (
    <React.Fragment>
      <Router.MetaTags title="Edit" />
      <React.Fragment>
        <Box color="$primary2" padding="$sm">
          <Breadcrumbs
            space="16px"
            items={[
              { title: 'Home', href: '/internal/home' },
              { title: 'Detail', href: '/internal/detail/:fln' },
            ]}
          />
        </Box>

        <Card collapse header="Edit">
          <Grid>
            <Grid.Col
              span={{ xs: 12 }}
              style={{
                padding: '40px',
                overflow: 'hidden',
              }}
            >
              <MemberInfo sendDataToParent={handleDataFromChild} />
            </Grid.Col>
          </Grid>
          <Grid style={{ float: 'right', padding: '20px' }}>
            <Grid.Col>
              <Button
                size="$lg"
                onClick={onRejectClick}
                style={{
                  width: '120px',
                }}
                aria-haspopup="dialog"
                isDisabled={released}
              >
                Reject
              </Button>
            </Grid.Col>{' '}
            <Grid.Col>
              {released ? (
                <Button
                  size="$lg"
                  onClick={onRelease}
                  style={{
                    width: '120px',
                  }}
                >
                  UnHold
                </Button>
              ) : (
                <Button
                  size="$lg"
                  onClick={onHoldClick}
                  style={{
                    width: '120px',
                  }}
                >
                  Hold
                </Button>
              )}
            </Grid.Col>{' '}
            <Grid.Col>
              <Button
                onClick={onSubmit}
                size="$lg"
                style={{
                  width: '120px',
                }}
                isDisabled={released}
              >
                Submit
              </Button>
            </Grid.Col>{' '}
          </Grid>
        </Card>

        <FormProvider
          state={rejectForm}
          onSubmit={onRejectSubmit}
          autoComplete="on"
        >
          <Modal
            title="Reject Reason"
            model="Reject Reason"
            isOpen={isOpen}
            closeOnClickOutside={false}
            onClose={() => setIsOpen(false)}
          >
            <Modal.Section>
              <DataTable
                tableState={rejectDataTableProps}
                title=""
                css={{
                  'abyss-data-table-header': {
                    display: 'none',
                    paddingTop: '20px',
                  },
                  'abyss-data-table-subheader-lower-container': { order: 1 },
                  'abyss-data-table-pagination-bottom-root': {
                    display: 'none',
                  },
                  'abyss-data-table-pagination-top-root': {
                    display: 'contents',
                  },
                }}
              />
              <Layout.Space />
              <Layout.Group alignLayout="right" style={{ marginTop: '20px' }}>
                <Button type="reset" variant="outline" onClick={onRejectCancel}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  onClick={() => {
                    rejectForm.handleSubmit(onRejectSubmit)();
                    if (rejectForm.formState.isValid) {
                      modal.close();
                    }
                  }}
                >
                  Submit
                </Button>
              </Layout.Group>
            </Modal.Section>
          </Modal>
        </FormProvider>
      </React.Fragment>
    </React.Fragment>
  );
};
