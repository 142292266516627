import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useForm } from '@abyss/web/hooks/useForm';
import { useOverlay } from '@abyss/web/hooks/useOverlay';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Badge } from '@abyss/web/ui/Badge';
import { Button } from '@abyss/web/ui/Button/v1/Button';
import { Card } from '@abyss/web/ui/Card';
import { DataTable } from '@abyss/web/ui/DataTable';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import { Modal } from '@abyss/web/ui/Modal/Modal';
import { Router } from '@abyss/web/ui/Router';
import { TextInput } from '@abyss/web/ui/TextInput';
import { useActionMutation } from '@src/api/mutation';
import { useFalloutQuery } from '@src/api/queries';
import { useSSOAuth } from '@src/hooks/useAuth';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { AuditTrail } from '../AuditTrail';
import { Timer } from './Timer';

export const Home = () => {
  const { navigate } = useRouter();
  const query = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const { ssoAuth } = useSSOAuth();
  const { data: jobIDResults, isLoading } = useFalloutQuery();
  const { mutateAsync: actionMutation, error: uploadError } =
    useActionMutation();
  const review = 'REVIEW';
  const inProgress = 'INPROGRESS';
  const reassigned = 'REASSIGNED';
  const modal = useOverlay('modal-form');
  const reassigneForm = useForm();
  const firstName = reassigneForm.watch('firstName');
  const lastName = reassigneForm.watch('lastName');
  const [reassignJobId, setReassignJobId] = useState<string>('');
  const [reassignStatus, setReassignStatus] = useState<string>('');

  const actions = [
    {
      onClick: async ({ row }: { row: any }) => {
        if (row?.original?.falloutStatus === 'NOT_STARTED') {
          try {
            const response = await actionMutation({
              falloutStatus: inProgress,
              jobId: row?.original?.jobId,
              assignTo: `${ssoAuth.lastName} , ${ssoAuth.firstName}`,
              userAction: review,
            });

            if (response.status === 200) {
              navigate(`/internal/detail/${row.original.jobId}`);
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          navigate(`/internal/detail/${row.original.jobId}`);
        }
      },

      checkDisabled: ({ row }: { row: any }) => {
        return false;
      },
      label: 'Review',
    },
    {
      onClick: ({ row }: { row: any }) => {
        setReassignJobId(row?.original?.jobId);
        setReassignStatus(row?.original?.falloutStatus);
        modal.open();
      },

      label: 'Reassign',
    },
  ];
  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Job ID',
        accessor: 'jobId',
        maxWidth: 80,
        hiddenDefaultFilters: [
          'greater',
          'greater-equal',
          'less',
          'less-equal',
        ],
      },
      {
        Header: 'File Name',
        accessor: 'filename',
        maxWidth: 150,
      },
      {
        Header: 'Received Date and Time',
        accessor: 'createdTimestamp',
        minWidth: 220,
        Cell: ({ value }: { value: any }) => {
          return (
            <span style={{ whiteSpace: 'nowrap' }}>
              {value.replace(/\+00:00$/, '')}
            </span>
          );
        },
      },
      {
        Header: 'Target Date and Time',
        accessor: 'targetDateTimestamp',
        minWidth: 200,
        Cell: ({ value }: { value: any }) => {
          return (
            <span style={{ whiteSpace: 'nowrap' }}>
              {value.replace(/\+00:00$/, '')}
            </span>
          );
        },
      },
      {
        Header: 'Assigned To',
        accessor: 'assignedTo',
        maxWidth: 150,
      },
      {
        Header: 'Status',
        accessor: 'falloutStatus',
        maxWidth: 150,
        Cell: ({ value }: { value: any }) => {
          let badgeValues: {
            badgeLabel: string;
            variant: 'success' | 'warning' | 'error' | 'info' | 'neutral';
            outline: any;
          };

          switch (value) {
            case 'ON_HOLD':
              badgeValues = {
                badgeLabel: value,
                variant: 'info',
                outline: true,
              };
              break;
            case 'INPROGRESS':
              badgeValues = {
                badgeLabel: value,
                variant: 'success',
                outline: true,
              };
              break;
            case 'REASSIGNED':
              badgeValues = {
                badgeLabel: value,
                variant: 'success',
                outline: true,
              };
              break;
            case 'SLA_RISK':
              badgeValues = {
                badgeLabel: value,
                variant: 'warning',
                outline: true,
              };
              break;
            case 'DELAYED':
              badgeValues = {
                badgeLabel: 'DELAYED',
                variant: 'error',
                outline: true,
              };
              break;
            default:
              badgeValues = {
                badgeLabel: 'NOT_STARTED',
                variant: 'neutral',
                outline: true,
              };
              break;
          }

          const { badgeLabel, variant } = badgeValues;

          return <Badge variant={variant}>{badgeLabel}</Badge>;
        },
      },
      {
        Header: 'Time Duration',
        // maxWidth: 100,
        Cell: ({ row }: { row: any }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Timer
                falloutStatus={row?.original?.falloutStatus}
                createdTimestamp={row?.original?.createdTimestamp}
              />
            </div>
          );
        },
      },
    ];
  }, []);
  const tableData = useDataTable({
    initialColumns: columns,
    // initialData: jobIDResults || [],
    noDataMessage: 'No results',
    showDownloadButton: false,
    individualActions: actions,
    // showGlobalFilter: true,
    // showFilterDataset: true,
    pageSizeOptions: [5, 10, 25],
    pageSizeDefault: 5,
    removeFilterColumns: ['timeDuration'],
    highlightRowOnHover: true,
    filterColumnTypes: {
      targetDateTime: {
        type: 'date',
      },
      receivedDateTime: {
        type: 'date',
      },
    },
  });

  const onReassignSubmit = async (data: any) => {
    // const response = await actionMutation({
    //   falloutStatus:
    //     reassignStatus === 'SLA_RISK' || reassignStatus === 'DELAYED'
    //       ? reassignStatus
    //       : reassigned,
    //   jobId: reassignJobId,
    //   assignTo: `${lastName} , ${firstName}`,
    //   userAction: reassigned,
    // });
    // if (response.status === 200) {
    //   reassigneForm.reset();
    //   modal.close();
    // }
  };
  const onReassignCancel = () => {
    reassigneForm.reset();
    modal.close();
  };

  useEffect(() => {
    if (jobIDResults) {
      tableData.setData(jobIDResults);
    }
  }, [jobIDResults]);
  return (
    <React.Fragment>
      <Router.MetaTags title="Home" />
      <React.Fragment>
        <Card collapse header="Fallout Queue">
          <Card.Section
            css={{
              'abyss-card-section': {
                paddingLeft: '10px',
                paddingRight: '10px',
              },
            }}
          >
            {/* {isPending ? ( */}
            <LoadingOverlay
              loadingTitle="Please Wait"
              loadingMessage="Retrieving Information."
              statusTitle="Status update"
              statusMessage="Sample message..."
              isDismissable
              ariaLoadingLabel="Example loading aria label"
              isLoading={isLoading}
              // isOpen={isOpen}
              // onClose={handleClose}
            >
              {/* ) : ( */}
              <DataTable
                title="Search Results"
                tableState={tableData}
                css={{
                  '&.abyss-data-table-scroll': {
                    order: 1,
                  },
                  'abyss-data-table-subheader-lower-container': {
                    order: 1,
                  },
                  'abyss-data-table-subheader-top-container': {
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingBottom: '20px',
                  },
                  'abyss-data-table-pagination-bottom-root': {
                    display: 'none',
                    order: 2,
                    justifyContent: 'end',
                  },
                  'abyss-data-table-pagination-top-root': {
                    display: 'contents',
                  },
                  'abyss-data-table-header': { display: 'none' },
                }}
              />{' '}
            </LoadingOverlay>

            {/* )} */}
          </Card.Section>
        </Card>
        <React.Fragment>
          <AuditTrail />
        </React.Fragment>
      </React.Fragment>
      <React.Fragment>
        <FormProvider
          state={reassigneForm}
          onSubmit={onReassignSubmit}
          autoComplete="on"
        >
          <Modal
            title="Enter Assignee Details"
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(false);
            }}
            model="modal-form"
          >
            <Modal.Section>
              <Grid>
                <>
                  <Grid.Col span={6}>
                    <TextInput
                      model="firstName"
                      label="First Name"
                      isClearable
                      validators={{ required: `Error: Enter a FirstName` }}
                      css={{
                        'abyss-select-input-root': {
                          padding: '20px',
                          marginBottom: '20px',
                        },
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      model="lastName"
                      label="Last Name"
                      isClearable
                      errorMessage="Enter a LastName"
                      validators={{ required: `Error: Enter a LastName` }}
                      css={{
                        'abyss-select-input-root': {
                          padding: '20px',
                        },
                      }}
                    />
                  </Grid.Col>
                </>
              </Grid>
              <Layout.Space />
              <Layout.Group alignLayout="right" style={{ marginTop: '0px' }}>
                <Button
                  type="reset"
                  variant="outline"
                  onClick={onReassignCancel}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  onClick={() => {
                    reassigneForm.handleSubmit(onReassignSubmit)();
                    if (reassigneForm.formState.isValid) {
                      modal.close();
                    }
                  }}
                >
                  Submit
                </Button>
              </Layout.Group>
            </Modal.Section>
          </Modal>
        </FormProvider>
      </React.Fragment>
    </React.Fragment>
  );
};
