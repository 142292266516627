import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { postUpdateAction } from './apis';

const useActionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postUpdateAction,
    onSuccess: (data) => {
      // if (data?.data?.falloutStatus === 'REASSIGNED') {
      queryClient.invalidateQueries({ queryKey: ['falloutData'] });
      // }
    },
    retry: (failureCount, error) => {
      const excludedStatusCodes = [400];
      return (
        !excludedStatusCodes.includes(
          (error as AxiosError)?.response?.status || 0
        ) && failureCount < 3
      );
    },
  });
};

export { useActionMutation };
